<template>
  <section class="route-picker">
    <h2>Cesta: <strong>{{ selectedRoute.text }}</strong></h2>
    <button @click="toggleRoutes" class="special-btn">{{ toggleButtonText }}</button>

    <ul v-show="areRoutesShown" class="routes-list">
      <li
        v-for="(route, index) in routes"
        :key="index"
        @click="select(route)"
        :class="{ selected: selectedRoute == route }"
        class="route-card"
      >
        <img :src="require(`@/assets/images/route/${route.img}`)" :alt="route.text" />
      </li>
    </ul>

    <button
      v-if="areRoutesShown"
      @click="toggleRoutes"
      class="special-btn"
    >Skrýt cesty</button>
  </section>
</template>

<script>
export default {
  props: {
    selectedRoute: Object,
    routes: Array
  },
  emits: ['valueChanged'],
  data() {
    return {
      areRoutesShown: false
    }
  },
  methods: {
    toggleRoutes() {
      this.areRoutesShown = !this.areRoutesShown
    },
    select(route) {
      this.toggleRoutes()
      this.$emit('valueChanged', route)
    }
  },
  computed: {
    toggleButtonText() {
      return this.areRoutesShown ? 'Skrýt cesty' : 'Zobrazit cesty'
    }
  }
}
</script>

<style scoped>
.routes-list {
  margin: 1em 0;
}

.route-card {
  padding: 1em;
  margin: .4em auto;
  border-radius: 4px;
  width: 90%;
  border: 1px dashed #bbbbbb;
  cursor: pointer;
}

.route-card img {
  width: 100%;
}

.selected {
  background-color: var(--main-color);
  border-color: var(--main-color);
}

@media only screen and (min-width: 375px) {
  .routes-list {
    display: flex;
    flex-wrap: wrap;
  }

  .route-card {
    width: 48%;
  }
}

@media only screen and (min-width: 768px) {
  .route-card {
    width: 32%;
  }
}

@media only screen and (min-width: 992px) {
  .route-card {
    width: 24%;
  }
}
</style>