/**
  * Normalizace klíče, otevřeného a šifrového textu
  * Validace
  * Otevřený text je posloupnost malých písmen abecedy
  * Šifrový text je posloupnost velkých písmen abecedy
*/

/**
  * Normalizuje klíč
  * Klíč musí být posloupnost dvou a více písmen
  * Př.: 'key1 WORD# ' => 'keyword'
  * @param {string} key - Klíč, který bude normalizován
  * @returns {string} Normalizovaný klíč
  * @throws {Error} Argument key musí být string
*/
export function normalizeKey(key) {
  checkString(key)
  if (key.length < 2)
    throw new Error(`${key} is not long enough (at least 2 letters)`)
  return normalizePlainText(key)
}

/**
  * Normalizuje klíč, který může mít více slov
  * Klíč musí být posloupnost dvou a více písmen, může obsahovat mezery
  * Př.: 'key1 WORD# ' => ['keyword', 2]
  * @param {string} key - Klíč, který bude normalizován
  * @returns {Array} Pole [normalizovaný klíč bez mezer, počet slov klíče]
  * @throws {Error} Argument key musí být string a musí mít alespoň 2 písmena
*/
export function normalizeMultipleKey(key) {
  checkString(key)

  const multipleKey =
    normalize(key).trim()
                  .replace(/[^A-Za-z ]/g, '')
                  .replace(/\s\s+/g, ' ')
                  .toLowerCase()
  
  const keys = multipleKey.trim().split(' ')
  const numOfWords = keys.length
  const normalizedMultipleKey = keys.join('')
  // klíč musí mít alespoň dva znaky
  if (normalizedMultipleKey.length < 2)
    throw new Error(`${key} is not valid multiple key`)

  return [normalizedMultipleKey, numOfWords]
}

/**
  * Normalizuje a validuje text
  * @param {string} text - Vstupní text
  * @returns {string} Normalizovaný text
  * @throws {Error} Argument text musí být string
*/
export function normalizeText(text) {
  checkString(text)
  return normalizePlainText(text)
}

/**
  * Normalizuje a validuje šifrový text
  * @param {string} text - Vstupní šifrový text
  * @returns {string} Normalizovaný šifrový text
  * @throws {Error} Argument text musí být string
*/
export function normalizeCipher(cipher) {
  checkString(cipher)
  return normalizeCipherText(cipher)
}

/**
  * Kontrola integeru
  * @param {number} number - Číslo
  * @throws {Error} Argument musí být integer
*/
export function checkInt(number) {
  if (typeof(number) !== 'number' || !isInt(number))
    throw new Error(`${number} is not an integer`)
}

/**
  * Normalizuje text, nahrazuje písmena s diakritikou
  * @param {string} text - Vstupní text
  * @returns {string} Normalizovaný text
*/
export function normalize(text) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

/**
  * Normalizuje otevřený text
  * @param {string} text - Vstupní text
  * @returns {string} Normalizovaný text
*/
export function normalizePlainText(text) {
  return normalize(text)
    .replace(/[^A-Za-z]/g, '')
    .toLowerCase()
}

/**
  * Normalizuje šifrový text
  * @param {string} text - Vstupní šifrový text
  * @returns {string} Normalizovaný šifrový text
*/
export function normalizeCipherText(text) {
  return normalize(text)
    .replace(/[^A-Za-z]/g, '')
    .toUpperCase()
}

function isInt(number) {
  let x
  return isNaN(number) ? !1 : (x = parseFloat(number), (0 | x) === x)
}

function checkString(str) {
  if (typeof(str) !== 'string')
    throw new Error(`${str} is not a string`)
}