<template>
  <section-header headline="Prolomení" />

  <div class="container">
    <solve-info>
      <h2>Informace k prolamování</h2>

      <p>Prolamuje šifrový text, který vznikla zašifrováním otevřeného textu v anglickém jazyce. Prolomení je navrženo pro šifry, jak jsou definovány v rámci tohoto projektu. Jinak je výsledek nejistý. Prolamování je úspěšnější pro delší šifrové texty.</p>

      <p><strong>Šifra Rail Fence</strong> je prolamována hrubou silou.</p>
      <p><strong>Sloupcová transpozice</strong> je prolamována horolezeckým algoritmem. Za určitých okolností dokáže prolamovat texty zašifrované klíčem až do délky 30.</p>
      <p><strong>Myszkowskiho transpozice</strong> je prolamována horolezeckým algoritmem. Dokáže prolamovat texty zašifrované klíčem až do délky 10.</p>
      <p><strong>Šifra ÜBCHI</strong> je prolamována horolezeckým algoritmem. Za určitých okolností dokáže prolamovat texty zašifrované klíčem až do délky 15.</p>
      <p><strong>Fleissnerova mřížka</strong> je prolamována horolezeckým algoritmem. Za určitých okolností dokáže prolamovat texty zašifrované mřížkou o rozměrech až 15x15.</p>
      <p><strong>Šifra Route</strong> je prolamována hrubou silou.</p>
    </solve-info>

    <div class="solve-select input-group">
      <label for="keyInput">Zvolit šifru</label>
      <select v-model="component">
        <option
          v-for="option in options"
          :key="option.name"
          :value="option.component"
        >{{ option.name }}</option>
      </select>
    </div>

    <component :is="component" />
  </div>
</template>

<script>
import SectionHeader from '@/components/base/SectionHeader'
import InfoSection from '@/components/base/InfoSection'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    'section-header': SectionHeader,
    'solve-info': InfoSection,
    'railfence-solve': defineAsyncComponent(() => import('@/components/solve/RailFenceSolve')),
    'columnar-solve': defineAsyncComponent(() => import('@/components/solve/ColumnarSolve')),
    'myszkowski-solve': defineAsyncComponent(() => import('@/components/solve/MyszkowskiSolve')),
    'ubchi-solve': defineAsyncComponent(() => import('@/components/solve/UbchiSolve')),
    'fleissner-solve': defineAsyncComponent(() => import('@/components/solve/FleissnerSolve')),
    'route-solve': defineAsyncComponent(() => import('@/components/solve/RouteSolve'))
  },
  data() {
    return {
      options: [
        { name: 'Šifra Rail Fence', component: 'railfence-solve' },
        { name: 'Sloupcová transpozice', component: 'columnar-solve' },
        { name: 'Myszkowskiho transpozice', component: 'myszkowski-solve' },
        { name: 'Šifra ÜBCHI', component: 'ubchi-solve' },
        { name: 'Fleissnerova mřížka', component: 'fleissner-solve' },
        { name: 'Šifra Route', component: 'route-solve'}
      ],
      component: 'columnar-solve'
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 768px;
}

.solve-select {
  max-width: 320px;
  margin: 2em auto;
}

/* inspirováno: https://stackoverflow.com/a/56593685 */
.solve-select select {  
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right .7em top 50%;
  background-size: .65em auto;
}

:deep(.special-btn) {
  margin-left: .6em;
}

:deep(.solve-alert) {
  background-color: var(--error-color);
  color: white;
  margin-top: 1em;
  padding: .6em 1em;
  border-radius: 4px;
}
</style>