import { checkInt, normalizeText } from '@/ciphers/normalization' 

// šifrování na základě funkce, která určí pořadí písmen
// musí být kompletní, poslední řádek nemůže obsahovat prázdná místa

/**
  * Zašifrovat text počtem sloupců a funkcí určující pořadí
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @param {function} orderFunc - Funkce určující pořadí písmen
  * @returns {string} Šifrový text
*/
export function encrypt(numOfCols, text, orderFunc, { normalize = true } = {}) {
  if (normalize) {
    check(numOfCols, text.length)
    text = normalizeText(text)
  }

  const rows = text.length / numOfCols
  const order = orderFunc(numOfCols, rows)
  
  return encryptByOrder(order, text)
}

/**
  * Dešifrovat text počtem sloupců a funkcí určující pořadí
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @param {function} orderFunc - Funkce určující pořadí písmen
  * @returns {string} Otevřený text
*/
export function decrypt(numOfCols, cipher, orderFunc, { normalize = true } = {}) {
  if (normalize) {
    check(numOfCols, cipher.length)
    cipher = normalizeText(cipher)
  }

  const rows = cipher.length / numOfCols
  const order = orderFunc(numOfCols, rows)
  
  return decryptByOrder(order, cipher)
}

// kontroluje, zda je cols integer větší roven 2
// a zda pro délku length vznikne úplná tabulka
function check(cols, length) {
  checkInt(cols)
  if (cols < 2)
    throw new Error(`${cols} is not enough cols, at least 2`)
  if (length % cols !== 0)
    throw new Error('incomplete text/cipher')
}

// pracuje s polem číselných pořadí jednotlivých písmen
// výsledkem je permutace textu na základě tohoto pořadí
function encryptByOrder(order, text) {
  let cipher = ''
  for (const index of order) {
    cipher += text.charAt(index)
  }
  return cipher.toUpperCase()
}

function decryptByOrder(order, cipher) {
  const plainArray = Array(order.length)
  for (let i = 0; i < order.length; i++) {
    plainArray[order[i]] = cipher.charAt(i)
  }
  return plainArray.join('').toLowerCase()
}