<template>
  <div class="section-header">
    <div class="container">
      <h1>{{ headline }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headline: String
  }
}
</script>

<style scoped>
.section-header {
  margin-bottom: 1.8em;
  padding: .9em 0;
  background-color: var(--secondary-color);
}

h1 {
  margin: 0;
  font-size: 1em;
  font-weight: 400;
  color: #444444;
}
</style>