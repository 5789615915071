import { normalizeKey } from '@/ciphers/normalization'

/**
  * Abecední (permutační) vyčíslení klíče
  * Parametr normalize nastavuje validaci a normalizaci
  * Př.: 'tomato' => [4, 2, 1, 0, 5, 3]
  * Indexování začíná od nuly
  * @param {string} key - Klíč, posloupnost písmen
  * @returns {Array} Abecední vyčíslení, pole indexů
*/
export function keyPermutation(key, { normalize = true } = {}) {
  if (normalize) key = normalizeKey(key)

  // pole indexů, např. [0, 1, 2, 3] pro délku 4
  const indices = [...Array(key.length).keys()]
  // setřídění indexů podle abecedního pořadí písmen klíče
  // získání umístění indexů ve výsledné permutaci
  indices.sort((x, y) => key.charCodeAt(x) - key.charCodeAt(y))
  // rozmístění indexů do výsledné permutace
  const permutation = Array(key.length)
  for (let i = 0; i < key.length; i++) {
    permutation[indices[i]] = i
  }

  return permutation
}

/**
  * Abecední (permutační) vyčíslení klíče, opakovaná písmena mají stejný index
  * Parametr normalize nastavuje validaci a normalizaci
  * Př.: 'tomato' => [3, 2, 1, 0, 3, 2]
  * Indexování začíná od nuly
  * @param {string} key - Klíč, posloupnost písmen
  * @returns {Array} Abecední vyčíslení, pole indexů
*/
export function keyPermutationDuplicate(key, { normalize = true } = {}) {
  if (normalize) key = normalizeKey(key)

  const sortedLetters = [...key].sort()
  const letterMap = new Map()
  let position = 0
  // mapování písmen na jejich pozice
  // mapován je pouze první výskyt
  for (const letter of sortedLetters) {
    if (!letterMap.has(letter)) {
      letterMap.set(letter, position++)
    }
  }
  // průchod přes písmena klíče, nahrazení indexy
  const permutation = Array(key.length)
  for (let i = 0; i < key.length; i++) {
    permutation[i] = letterMap.get(key.charAt(i))
  }

  return permutation
}