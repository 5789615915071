// náhodně vyplní mřížku grille
export function randomlyFillIn(grille) {
  const size = grille.length
  for (let row = 0; row < Math.floor(size / 2); row++) {
    for (let col = 0; col < size / 2; col++) {
      if (grille[row][col] === 0) {
        switch (getRandomOffset()) {
          case 0:
            grille[row][col] = 1
            grille[col][size - 1 - row] = 2
            grille[size - 1 - row][size - 1 - col] = 2
            grille[size - 1 - col][row] = 2
            break
          case 1:
            grille[row][col] = 2
            grille[col][size - 1 - row] = 1
            grille[size - 1 - row][size - 1 - col] = 2
            grille[size - 1 - col][row] = 2
            break
          case 2:
            grille[row][col] = 2
            grille[col][size - 1 - row] = 2
            grille[size - 1 - row][size - 1 - col] = 1
            grille[size - 1 - col][row] = 2
            break
          case 3:
            grille[row][col] = 2
            grille[col][size - 1 - row] = 2
            grille[size - 1 - row][size - 1 - col] = 2
            grille[size - 1 - col][row] = 1
            break
        }
      }
    }
  }
}

// náhodné číslo z [0, 1, 2, 3]
function getRandomOffset() {
  return Math.floor(Math.random() * Math.floor(4))
}