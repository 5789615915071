<template>
  <section-header headline="Fleissnerova mřížka" />
  
  <div class="container">
    <cipher-info>
      <h2>Fleissnerova mřížka</h2>

      <p>Jedná se o transpoziční šifrovací metodu, která nese jméno rakouského plukovníka Edouarda Fleissnera von Wostrowitze, který v roce 1881 napsal příručku o vojenské kryptografii. Metoda získala velkou popularitu. Jules Verne ji zakomponoval do svého románu Matyáš Sandorf. Fleissnerovu mřížku používala také německá armáda za 1. světové války - nejčastěji v rozměrech 5x5 a 10x10.</p>
      <p>Mřížkou se rozumí fyzická pomůcka většinou čtvercových rozměrů vyrobená například z papíru nebo lepenky. Vybraná políčka mřížky jsou vystřižena, takže se skrz ně dá psát. <strong>Fleissnerova mřížka má tvar čtverce a funguje tak, že se v průběhu šifrování otáčí. Celkem je použita ve čtyřech orientacích (postupně se rotuje o 90° ve směru hodinových ručiček). Skrz vystřižená políčka se píše otevřený text.</strong></p>
      <p>Šifrování Fleissnerovou mřížkou si vyzkoušejte pomocí formuláře níže. Mřížku si můžete interaktivně sestavit - vybrat rozměr a klikáním zvolit políčka. Také máte možnost náhodně mřížku doplnit. Poté již můžete psát otevřený (šifrový) text.</p>
    </cipher-info>

    <grille @valueChanged="grilleChanged" />

    <div v-show="isGrilleComplete">
      <div class="text-group">
        <plain-text-area
          :value="plainText"
          :maxLength="maxTextLength"
          :isValid="isPlainTextValid"
          :invalidFeedback="invalidPlainTextFeedback"
          @valueChanged="plainTextChanged"
        />
        <cipher-text-area
          :value="cipherText"
          :maxLength="maxTextLength"
          :isValid="isCipherTextValid"
          :invalidFeedback="invalidCipherTextFeedback"
          @valueChanged="cipherTextChanged"
        />
      </div>

      <button 
        v-show="!isPlainTextValid"
        @click="completePlainText"
        class="special-btn"
      >Doplnit otevřený text</button>
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/base/SectionHeader'
import InfoSection from '@/components/base/InfoSection'
import FleissnerGrille from '@/components/FleissnerGrille'
import PlainTextArea from '@/components/input/PlainTextArea'
import CipherTextArea from '@/components/input/CipherTextArea'

import fleissner from '@/ciphers/fleissner'

export default {
  components: {
    'section-header': SectionHeader,
    'cipher-info': InfoSection,
    'grille': FleissnerGrille,
    'plain-text-area': PlainTextArea,
    'cipher-text-area': CipherTextArea,    
  },
  data() {
    return {
      isEncrypting: true,
      grille: null,
      plainText: '',
      cipherText: ''
    }
  },
  methods: {
    grilleChanged(grille) {
      this.plainText = ''
      this.cipherText = ''
      this.grille = null
      if (grille != null)
        this.convert(grille)
    },
    // z mřížky pro zobrazení vytvoří mřížku pro převod
    convert(grille) {
      this.grille = []
      for (const row of grille)
        this.grille.push(
          row.map(value => (value === 1 ? true : false))
        )
    },
    plainTextChanged(value) {
      this.plainText = value
      this.isEncrypting = true
      this.encrypt()
    },
    cipherTextChanged(value) {
      this.cipherText = value
      this.isEncrypting = false
      this.decrypt()
    },
    encrypt() {
      this.cipherText = ''
      if (!this.isPlainTextValid) return
      this.cipherText = fleissner.encrypt(
        this.grille,
        this.plainText,
        { normalize: false }
      )
    },
    decrypt() {
      this.plainText = ''
      if (!this.isCipherTextValid) return
      this.plainText = fleissner.decrypt(
        this.grille,
        this.cipherText,
        { normalize: false }
      )
    },
    completePlainText() {
      const remainder = this.maxTextLength - this.plainText.length
      this.plainText += 'x'.repeat(remainder)
      this.encrypt()
    },
    invalidTextFeedback(length) {
      return `Nedostatečná délka - ${length}/${this.maxTextLength} písmen`
    }
  },
  computed: {
    isGrilleComplete() {
      return this.grille != null
    },
    maxTextLength() {
      if (this.grille == null) return 0
      const size = this.grille.length
      return size * size - (size % 2 == 1 ? 1 : 0)
    },
    isPlainTextValid() {
      return !this.isEncrypting || this.maxTextLength === this.plainText.length
    },
    isCipherTextValid() {
      return this.isEncrypting || this.maxTextLength === this.cipherText.length
    },
    invalidPlainTextFeedback() {
      return this.invalidTextFeedback(this.plainText.length)
    },
    invalidCipherTextFeedback() {
      return this.invalidTextFeedback(this.cipherText.length)
    }
  }
}
</script>