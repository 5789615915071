import { encrypt, decrypt } from '@/ciphers/route/common'

/**
  * Zašifrovat text počtem sloupců
  * Cesta: spirálně dovnitř ve směru hodinových ručiček
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptClockwiseInwards(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderClockwiseInwards, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: spirálně dovnitř ve směru hodinových ručiček
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptClockwiseInwards(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderClockwiseInwards, { normalize })
}

/**
  * Zašifrovat text počtem sloupců
  * Cesta: spirálně ven proti směru hodinových ručiček
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptAntiClockwiseOutwards(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderAntiClockwiseOutwards, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: spirálně ven proti směru hodinových ručiček
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptAntiClockwiseOutwards(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderAntiClockwiseOutwards, { normalize })
}

/**
  * Zašifrovat text počtem sloupců
  * Cesta: spirálně dovnitř proti směru hodinových ručiček
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptAntiClockwiseInwards(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderAntiClockwiseInwards, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: spirálně dovnitř proti směru hodinových ručiček
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptAntiClockwiseInwards(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderAntiClockwiseInwards, { normalize })
}

/**
  * Zašifrovat text počtem sloupců
  * Cesta: spirálně ven ve směru hodinových ručiček
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptClockwiseOutwards(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderClockwiseOutwards, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: spirálně ven ve směru hodinových ručiček
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptClockwiseOutwards(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderClockwiseOutwards, { normalize })
}

function orderClockwise(cols, rows, reverse) {
  let rowBegin = 0
  let rowEnd = rows - 1
  let colBegin = 0
  let colEnd = cols - 1
  let order = []

  while (rowBegin <= rowEnd && colBegin <= colEnd) {
    //průchod dolů
    for (let i = rowBegin; i <= rowEnd; i++) {
      order.push(i * cols + colEnd)
    }
    colEnd--

    //průchod doleva
    for (let i = colEnd; i >= colBegin; i--) {
      order.push(rowEnd * cols + i)
    }
    rowEnd--

    //průchod nahoru
    if (colBegin <= colEnd) {
      for (let i = rowEnd; i >= rowBegin; i--) {
        order.push(i * cols + colBegin)
      }
    }
    colBegin++

    //průchod doprava
    if (rowBegin <= rowEnd) {
      for (let i = colBegin; i <= colEnd; i++) {
        order.push(rowBegin * cols + i)
      }
    }
    rowBegin++
  }

  return reverse ? order.reverse() : order
}

function orderClockwiseInwards(cols, rows) {
  return orderClockwise(cols, rows, false)
}

function orderAntiClockwiseOutwards(cols, rows) {
  return orderClockwise(cols, rows, true)
}

function orderAntiClockwise(cols, rows, reverse) {
  let rowBegin = 0
  let rowEnd = rows - 1
  let colBegin = 0
  let colEnd = cols - 1
  let order = []

  while (rowBegin <= rowEnd && colBegin <= colEnd) {
    // průchod doleva
    for (let i = colEnd; i >= colBegin; i--) {
      order.push(rowBegin * cols + i)
    }
    rowBegin++

    // průchod dolů
    for (let i = rowBegin; i <= rowEnd; i++) {
      order.push(i * cols + colBegin)
    }
    colBegin++

    // průchod doprava
    if (rowBegin <= rowEnd) {
      for (let i = colBegin; i <= colEnd; i++) {
        order.push(rowEnd * cols + i)
      }
    }
    rowEnd--

    // průchod nahoru
    if (colBegin <= colEnd) {
      for (let i = rowEnd; i >= rowBegin; i--) {
        order.push(i * cols + colEnd)
      }
    }
    colEnd--
  }

  return reverse ? order.reverse() : order
}

function orderAntiClockwiseInwards(cols, rows) {
  return orderAntiClockwise(cols, rows, false)
}

function orderClockwiseOutwards(cols, rows) {
  return orderAntiClockwise(cols, rows, true)
}

// vrací objekt se všemi funkcemi
export default {
  encryptClockwiseInwards,
  decryptClockwiseInwards,
  encryptAntiClockwiseOutwards,
  decryptAntiClockwiseOutwards,
  encryptAntiClockwiseInwards,
  decryptAntiClockwiseInwards,
  encryptClockwiseOutwards,
  decryptClockwiseOutwards
}