<template>
  <header class="navbar">
    <div class="container">
      <router-link to="/" class="brand">Transpoziční šifry</router-link>
      
      <img
        @click="toggleMenu"
        src="@/assets/images/menu.png"
        class="menu-icon"
        alt="menu"
      />

      <nav :class="{ 'show-nav': isNavShown }">
        <router-link to="/sifra-railfence">Rail Fence</router-link>
        <router-link to="/sloupcova-transpozice">Sloupcová</router-link>
        <router-link to="/myszkowskiho-transpozice">Myszkowski</router-link>
        <router-link to="/sifra-ubchi">ÜBCHI</router-link>
        <router-link to="/fleissnerova-mrizka">Fleissner</router-link>
        <router-link to="/sifra-route">Route</router-link>
        <span class="nav-separator"></span>
        <router-link to="/prolomit" class="special-link">Prolomit</router-link>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isNavShown: false
    }
  },
  watch: {
    '$route' () {
      this.isNavShown = false
    }
  },
  methods: {
    toggleMenu() {
      this.isNavShown = !this.isNavShown
    }
  }
}
</script>

<style scoped>
.navbar {
  padding: 2.4em 0;
  background-color: var(--main-color);
}

.navbar a {
  text-decoration: none;
  color: white;
}

.brand {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.4em;
  font-weight: 400;
}

.menu-icon {
  height: 22px;
  float: right;
  cursor: pointer;
}

nav {
  display: none;
  margin-top: 1em;
}

.show-nav {
  display: block;
}

nav a {
  display: block;
  padding: .4em .5em;
  border: 1px solid transparent;
}

nav a:hover {
  color: #eeeeee;
}

nav a.router-link-exact-active {
  border-color: white;
  border-radius: 0;
}

.special-link {
  background-color: var(--distinctive-color);
  border-radius: 3px;
}

.nav-separator {
  display: block;
  width: 20%;
  border-bottom: 1px solid white;
  margin: 1em auto;
}

@media only screen and (min-width: 992px) {
  .container {
    display: flex;
    justify-content: space-between;
  }

  .menu-icon {
    display: none;
  }

  nav {
    display: block;
    margin-top: 0;
  }

  nav a {
    display: inline;
    margin-left: .5em;
    padding-right: .5em;
    padding-left: .5em;
  }

  .nav-separator {
    display: inline;
    width: auto;
    border-left: 1px solid white;
    margin: 0 .5em;
  }
}
</style>