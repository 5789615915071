<template>
  <section-header headline="Sloupcová transpozice" />

  <div class="container">
    <cipher-info>
      <h2>Sloupcová transpozice</h2>

      <p>Je považována za nejpopulárnější transpoziční šifrovací systém. Používala se zejména ve 2. polovině 19. století a 1. polovině 20. století. Například ve 20. letech minulého století sloužila jako hlavní šifra Irské republikánské armády (IRA).</p>
      <p><strong>Princip spočívá v sestavení tabulky, do které se po řádcích vepisuje otevřený text. Šifrový text pak vzniká přepsáním sloupců. Rozměry tabulky a pořadí sloupců určuje klíč.</strong></p>
      <p>Sloupcovou transpozici si můžete vyzkoušet pomocí formuláře níže.</p>
      <p>Po zadání klíče a otevřeného (šifrového) textu se objeví pod formulářem tabulka. Na ní jde vidět princip šifry. Pod písmeny klíče jsou uvedena čísla, která určují jejich abecední pořadí (abecední vyčíslení klíče). Podle tohoto pořadí jsou seřazeny sloupce tabulky do šifrového textu.</p>
    </cipher-info>

    <key-input
      :value="keyValue"
      :isValid="isKeyValid"
      @valueChanged="keyChanged"
    />

    <div v-show="isKeyValid">
      <div class="text-group">
        <plain-text-area
          :value="plainText"
          @valueChanged="plainTextChanged"
        />
        <cipher-text-area
          :value="cipherText"
          @valueChanged="cipherTextChanged"
        />
      </div>

      <columnar-table
        :keyValue="keyValue"
        :permutation="permutation"
        :isValid="isKeyValid"
        :text="plainText"
      />
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/base/SectionHeader'
import InfoSection from '@/components/base/InfoSection'
import KeyInput from '@/components/input/KeyInput'
import PlainTextArea from '@/components/input/PlainTextArea'
import CipherTextArea from '@/components/input/CipherTextArea'
import ColumnarTable from '@/components/ColumnarTable'

import columnar from '@/ciphers/columnar'
import { keyPermutation } from '@/ciphers/permutation'

export default {
  components: {
    'section-header': SectionHeader,
    'cipher-info': InfoSection,
    'key-input': KeyInput,
    'plain-text-area': PlainTextArea,
    'cipher-text-area': CipherTextArea,
    'columnar-table': ColumnarTable
  },
  data() {
    return {
      isEncrypting: true,
      keyValue: '',
      plainText: '',
      cipherText: ''
    }
  },
  methods: {
    keyChanged(value) {
      this.keyValue = value
      if (this.isKeyValid) {
        if (this.isEncrypting) this.encrypt()
        else this.decrypt()
      }
    },
    plainTextChanged(value) {
      this.plainText = value
      this.isEncrypting = true
      this.encrypt()
    },
    cipherTextChanged(value) {
      this.cipherText = value
      this.isEncrypting = false
      this.decrypt()
    },
    encrypt() {
      this.cipherText = columnar.encryptByPermutation(
        this.permutation,
        this.plainText,
        { normalize: false }
      )
    },
    decrypt() {
      this.plainText = columnar.decryptByPermutation(
        this.permutation,
        this.cipherText,
        { normalize: false }
      )
    }
  },
  computed: {
    permutation() {
      return keyPermutation(this.keyValue, { normalize: false })
    },
    isKeyValid() {
      return this.keyValue.length > 1
    }
  }
}
</script>