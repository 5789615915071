<template>
  <section-header headline="Úvodní stránka" />

  <main>
    <div class="container">

      <article>
        <h2>Úvod do problematiky</h2>

        <p>Kryptologie je věda, která zkoumá způsoby utajování zpráv. Dělí se na kryptografii, kryptoanalýzu a steganografii. Kryptografie popisuje metody šifrování zpráv. Kryptoanalýza se zabývá slabými místy šifer za účelem jejich prolomení. Úkolem steganografie je skrytí samotné existence zpráv. Větvení kryptologie je znázorněno na následujícím obrázku.</p>

        <img src="@/assets/images/crypto-classification.svg" alt="cryptology classification">

        <p>Kryptografie rozeznává symetrické a asymetrické šifry. Zatímco symetrické šifry používají pro šifrování a dešifrování totožný klíč, pro asymetrické toto neplatí. U asymetrických šifer definujeme dva typy klíčů - veřejný a soukromý. Utajen je pouze soukromý klíč, neboť je schopen dešifrovat každou zprávu zašifrovanou veřejným klíčem. Předmětem této práce jsou transpoziční šifry, které jsou podkategorií historických symetrických šifer.</p>

        <p>Transpoziční a substituční šifry tvoří základní skupiny historických šifer. Rozdělení je založeno na charakteru použité operace. <strong>Transpozice spočívá ve změně pořadí písmen</strong>. Substituce znamená nahrazení písmena jiným písmenem.</p>

        <p>Pro dosažení důvěrnosti komunikace je třeba přenášená data šifrovat. To obnáší transformaci dat do takové podoby, která bude srozumitelná pouze zamýšlenému příjemci. Požadovanou funkci poskytují šifrovací systémy (kryptosystémy) definující potřebné algoritmy - generování klíče, šifrování a dešifrování. Obecné schéma symetrického šifrovacího systému je znázorněno na následujícím obrázku.</p>

        <img src="@/assets/images/cryptosystem.png" alt="cryptosystem">
      </article>

      <aside>
        <p><strong>Tato webová aplikace umožňuje používat transpoziční šifry pro šifrování a dešifrování textu a do určité míry i šifrový text prolamovat.</strong></p>

        <p>Vytvořeno jako praktická část bakalářské práce Transpoziční šifry vypracované na Katedře informatiky Přírodovědecké fakulty Univerzity Palackého v Olomouci.</p>

        <p>&copy; 2021 Jan Bičan</p>
      </aside>
    </div>
  </main>
</template>

<script>
import SectionHeader from '@/components/base/SectionHeader'

export default {
  components: {
    'section-header': SectionHeader
  }
}
</script>

<style scoped>
h2 {
  color: var(--distinctive-color);
  font-weight: 700;
  font-size: 1em;
}

img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

section {
  border-top: 1px solid black;
  margin-top: 1em;
}

@media only screen and (min-width: 768px) {
  .container {
    display: flex;
  }

  article {
    margin-right: 2em;
  }

  aside {
    max-width: 248px;
    margin-top: 0;
    border-top: none;
  }
}
</style>
