<template>
  <div class="app">
    <NavBar />
    <router-view/>
  </div>
</template>

<script>
import NavBar from '@/components/base/NavBar'

export default {
  components: { NavBar }
}
</script>

<style>
:root {
  --main-color: #7e8a97;
  --main-color-lighten: #b1b8c0;
  --secondary-color: #eeeeee;
  --error-color: #9b6667;
  --distinctive-color: #9b6667;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
}

main {
  margin: 2em 0;
}

a {
  text-decoration: none;
  color: var(--distinctive-color)
}

a:hover {
  text-decoration: underline;
}

h2 {
  font-size: 1em;
  font-weight: 400;
  color: #444;
  margin: 0 0 .3em;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

button {
  background: none;
  padding: .4em .8em;
  border-radius: 4px;
  font-size: 1em;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
}

button:focus {
  outline: none;
}

p {
  hyphens: auto;
}

p:last-child {
  margin-bottom: 0;
}

/* layout */

.app {
  padding-bottom: 1.6em;
}

.container {
  width: 95%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 1em;
}

/* forms */

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2em;
}

label {
  color: #444444;
  margin-bottom: .3em;
}

input,
textarea,
select {
  padding: .6em .8em;
  font-size: 16px;
  color: #444444;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #bbbbbb;
  border-radius: 3px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

textarea {
  resize: vertical;
}

.text-group {
  margin: 2em 0 0;
}

/* state */

.error {
  color: var(--error-color);
  border-color: var(--error-color);
}

.error-msg {
  font-size: .9em;
  margin: .4em 0 0;
}

/* components */

.special-btn {
  border: 1px solid #343a40;
  color: #343a40;
}

.special-btn:hover {
  background-color: #343a40;
  color: white;
}

.danger-btn {
  border: 1px solid var(--error-color);
  color: var(--error-color);
}

.danger-btn:hover {
  background-color: var(--error-color);
  color: white;
}

.info-container h2 {
  color: var(--distinctive-color);
  font-weight: 700;
}

/* media queries */

@media only screen and (min-width: 992px) {
  .text-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /* plain-text-area a cipher-text-area vedle sebe */
  .text-group > * {
    width: 49%;
  }
}
</style>
