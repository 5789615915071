<template>
  <div class="input-group">
    <label for="MultipleKeyInput">Klíč</label>
    <input
      type="text"
      id="MultipleKeyInput"
      :value="value"
      @input="valueChanged"
      :class="{ error: !isValid }"
      autocomplete="off"
    />
    <div v-show="!isValid" class="error-msg error">Klíč musí mít alespoň 2 písmena, může mít víc slov oddělených mezerou</div>
  </div>
</template>

<script>
import { normalize } from '@/ciphers/normalization'

export default {
  props: {
    value: String,
    isValid: Boolean
  },
  emits: ['valueChanged'],
  methods: {
    valueChanged(event) {
      const value = this.getAdjustedText(event.target.value)
      event.target.value = value
      this.$emit('valueChanged', value)
    },
    getAdjustedText(text) {
      return normalize(text)
        .trimLeft()
        .replace(/[^A-Za-z ]/g, '')
        .replace(/\s\s+/g, ' ')
        .toLowerCase()
    },
  }
}
</script>