<template>
  <section-header headline="Myszkowskiho transpozice" />

  <div class="container">
    <cipher-info>
      <h2>Myszkowskiho transpozice</h2>

      <p>Jde o upravenou <router-link to="/sloupcova-transpozice">sloupcovou transpozici</router-link>. Rozdíl spočívá v odlišném přístupu k opakujícím se písmenům klíče. Tuto šifru navrhl Émile Victor Théodore Myszkowski v roce 1902. Šlo o francouzského plukovníka ve výslužbě, který napsal knihu Cryptographie indéchiffrable.</p>
      <p>V případě, že jsou všechna písmena v klíči unikátní, Myszkowskiho transpozice pro daný otevřený text vytváří identický šifrový text jako sloupcová transpozice. Pro využití odlišného postupu je tedy nezbytné zvolit takový klíč, v němž budou alespoň dvě stejná písmena. Abecední vyčíslení klíče bude mít pro totožná písmena opakující se hodnoty.</p>
      <p><strong>Při šifrování se sestavuje stejná tabulka jako v případě sloupcové transpozice - počet sloupců je určen délkou klíče. Otevřený text se následně vepisuje po řádcích do tabulky. Šifrový text se získá přepsáním sloupců tabulky v pořadí, které určuje abecední vyčíslení s tím, že sloupce se stejnou hodnotou se zpracovávají průběžně zleva doprava.</strong></p>
      <p>Myszkowskiho transpozici si můžete vyzkoušet pomocí formuláře níže.</p>
    </cipher-info>

    <key-input
      :value="keyValue"
      :isValid="isKeyValid"
      @valueChanged="keyChanged"
    />

    <div v-show="isKeyValid">
      <div class="text-group">
        <plain-text-area
          :value="plainText"
          @valueChanged="plainTextChanged"
        />
        <cipher-text-area
          :value="cipherText"
          @valueChanged="cipherTextChanged"
        />
      </div>

      <columnar-table
        :keyValue="keyValue"
        :permutation="permutation"
        :isValid="isKeyValid"
        :text="plainText"
      />
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/base/SectionHeader'
import InfoSection from '@/components/base/InfoSection'
import KeyInput from '@/components/input/KeyInput'
import PlainTextArea from '@/components/input/PlainTextArea'
import CipherTextArea from '@/components/input/CipherTextArea'
import ColumnarTable from '@/components/ColumnarTable'

import myszkowski from '@/ciphers/myszkowski'
import { keyPermutationDuplicate } from '@/ciphers/permutation'

export default {
  components: {
    'section-header': SectionHeader,
    'cipher-info': InfoSection,
    'key-input': KeyInput,
    'plain-text-area': PlainTextArea,
    'cipher-text-area': CipherTextArea,
    'columnar-table': ColumnarTable
  },
  data() {
    return {
      isEncrypting: true,
      keyValue: '',
      plainText: '',
      cipherText: ''
    }
  },
  methods: {
    keyChanged(value) {
      this.keyValue = value
      if (this.isKeyValid) {
        if (this.isEncrypting) this.encrypt()
        else this.decrypt()
      }
    },
    plainTextChanged(value) {
      this.plainText = value
      this.isEncrypting = true
      this.encrypt()
    },
    cipherTextChanged(value) {
      this.cipherText = value
      this.isEncrypting = false
      this.decrypt()
    },
    encrypt() {
      this.cipherText = myszkowski.encryptByPermutation(
        this.permutation,
        this.plainText,
        { normalize: false }
      )
    },
    decrypt() {
      this.plainText = myszkowski.decrypt(
        this.keyValue,
        this.cipherText,
        { normalize: false }
      )
    }
  },
  computed: {
    permutation() {
      return keyPermutationDuplicate(this.keyValue, { normalize: false })
    },
    isKeyValid() {
      return this.keyValue.length > 1
    }
  }
}
</script>