/**
  * Vytvoří prázdné 2-rozměrné pole o délce length
  * @param {string} length - Délka pole
  * @returns {Array} 2-rozměrné pole
*/
export function createTwoDimArray(length) {
  const array = Array(length)
  for (let i = 0; i < length; i++) {
    array[i] = []
  }
  return array
}