<template>
  <section-header headline="Šifra Rail Fence" />

  <div class="container">
    <cipher-info>
      <h2>Šifra Rail Fence</h2>

      <p>Jednoduše a rychle aplikovatelná šifra, která však poskytuje malou míru bezpečnosti. Při použití vzniká mřížka připomínající plot, z čehož vyplývá název. Alternativně se pojmenovává také jako zig-zag šifra.</p> 
      <p>Popularitu získala zejména v raných desetiletích vývoje kryptografie. Se vznikem komplexnějších šifrovacích systémů byla odsunuta do ústraní. Později se vyskytla za Americké občanské války, kdy se používala k utajování vojenských informací a zpráv zasílaných agenty Unie a Konfederace. V současnosti se s ní lze setkat např. v geocachingu (mystery cache).</p>
      <p><strong>Princip spočívá v psaní otevřeného textu do mřížky, jejíž počet řádků určuje klíč. Řádky jsou s každým písmenem střídány a dochází k posunu vlevo. Otevřený text vytváří v mřížce véčka. Šifrový text vznikne přepsáním řádků mřížky.</strong></p>
      <p>Šifru Rail Fence si můžete vyzkoušet pomocí formuláře níže</p>
      <p>Po zadání klíče a otevřeného (šifrového) textu se pod formulářem objeví mřížka. Vzniklé řádky jsou přepsány do šifrového textu.</p>
    </cipher-info>

    <key-number-input
      :value="keyValue"
      :isValid="isKeyValid"
      label="Klíč (počet řádků)"
      invalidFeedback="Počet řádků musí být alespoň 2"
      @valueChanged="keyChanged"
    />

    <div v-show="isKeyValid">
      <div class="text-group">
        <plain-text-area
          :value="plainText"
          @valueChanged="plainTextChanged"
        />
        <cipher-text-area
          :value="cipherText"
          @valueChanged="cipherTextChanged"
        />
      </div>

      <encryption-grid
        v-show="plainText.length != 0"
        :keyValue="keyValue"
        :isValid="isKeyValid"
        :text="plainText"
      />
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/base/SectionHeader'
import InfoSection from '@/components/base/InfoSection'
import KeyNumberInput from '@/components/input/KeyNumberInput'
import PlainTextArea from '@/components/input/PlainTextArea'
import CipherTextArea from '@/components/input/CipherTextArea'
import RailFenceGrid from '@/components/RailFenceGrid'

import railfence from '@/ciphers/railfence'

export default {
  components: {
    'section-header': SectionHeader,
    'cipher-info': InfoSection,
    'key-number-input': KeyNumberInput,
    'plain-text-area': PlainTextArea,
    'cipher-text-area': CipherTextArea,
    'encryption-grid': RailFenceGrid
  },
  data() {
    return {
      isEncrypting: true,
      keyValue: 3,
      plainText: '',
      cipherText: ''
    }
  },
  methods: {
    keyChanged(value) {
      this.keyValue = value
      if (this.isKeyValid) {
        if (this.isEncrypting) this.encrypt()
        else this.decrypt()
      }
    },
    plainTextChanged(value) {
      this.plainText = value
      this.isEncrypting = true
      this.encrypt()
    },
    cipherTextChanged(value) {
      this.cipherText = value
      this.isEncrypting = false
      this.decrypt()
    },
    encrypt() {
      this.cipherText = railfence.encrypt(
        this.keyValue,
        this.plainText,
        { normalize: false }
      )
    },
    decrypt() {
      this.plainText = railfence.decrypt(
        this.keyValue,
        this.cipherText,
        { normalize: false }
      )
    },
  },
  computed: {
    isKeyValid() {
      return this.keyValue >= 2
    }
  }
}
</script>