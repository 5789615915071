import { keyPermutation } from '@/ciphers/permutation'
import { normalizeMultipleKey } from '@/ciphers/normalization'
import columnar from '@/ciphers/columnar'

const DUMMY_LETTER = 'w' // klamač

/**
  * Zašifrovat text klíčem
  * Proběhne validace a normalizace
  * @param {string} key - Šifrový klíč, může obsahovat mezery
  * @param {string} text - Otevřený text
  * @returns {object} Šifrový a částečně šifrový text,
  *                   parametry cipher, partCipher
*/
export function encrypt(key, text, { dummyLetter = DUMMY_LETTER } = {}) {
  const [normalizedKey, numOfWords] = normalizeMultipleKey(key)
  const permutation = keyPermutation(normalizedKey, { normalize: false })
  return encryptByPermutation(permutation, text, { numOfWords, dummyLetter })
}

/**
  * Zašifrovat text abecedním vyčíslením
  * Parametr numOfWords určuje počet slov klíče
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} permutation - Abecední vyčíslení
  * @param {string} text - Otevřený text
  * @returns {object} Šifrový a částečně šifrový text,
  *                   parametry cipher, partCipher
*/
export function encryptByPermutation(permutation, text,
  { numOfWords = 1, normalize = true, dummyLetter = DUMMY_LETTER } = {}) {
  
  const result = { cipher: '', partCipher: '' }
  if (text.length === 0) return result
  // první transpozice
  result.partCipher = columnar.encryptByPermutation(permutation, text, { normalize })
  // přidání "klamačů"
  result.partCipher += dummyLetter.repeat(numOfWords)
  result.partCipher = result.partCipher.toLowerCase()
  // druhá transpozice
  result.cipher = columnar.encryptByPermutation(permutation, result.partCipher)

  return result
}

/**
  * Dešifrovat text klíčem
  * Proběhne validace a normalizace
  * @param {string} key - Šifrový klíč, může obsahovat mezery
  * @param {string} cipher - Šifrový text
  * @returns {object} Otevřený a částečně šifrový text,
  *                   parametry text, partCipher
*/
function decrypt(key, cipher) {
  const [normalizedKey, numOfWords] = normalizeMultipleKey(key)
  const permutation = keyPermutation(normalizedKey, { normalize: false })
  return decryptByPermutation(permutation, cipher, { numOfWords })
}

/**
  * Dešifrovat text abecedním vyčíslením
  * Parametr numOfWords určuje počet slov klíče
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} permutation - Abecední vyčíslení
  * @param {string} cipher - Šifrový text
  * @returns {object} Otevřený a částečně šifrový text,
  *                   parametry text, partCipher
*/
function decryptByPermutation(permutation, cipher,
  { numOfWords = 1, normalize = true} = {}) {

  const result = { text: '', partCipher: '' }
  if (cipher.length === 0) return result
  // první transpozice
  result.partCipher = columnar.decryptByPermutation(permutation, cipher, { normalize })
  // druhá transpozice s odstraněnými klamači
  result.text = columnar.decryptByPermutation(permutation, result.partCipher.slice(0, -numOfWords))

  return result
}

// vrací objekt se všemi funkcemi
export default {
  encrypt,
  encryptByPermutation,
  decrypt,
  decryptByPermutation
}