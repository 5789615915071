<template>
  <div class="info-container">
    <section v-if="isShown" class="info-section">
      <slot></slot>
    </section>

    <div class="btn-container">
      <button @click="toggle" class="special-btn">{{ buttonText }}</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShown: false,
    }
  },
  methods: {
    toggle() {
      this.isShown = !this.isShown
    }
  },
  computed: {
    buttonText() {
      return this.isShown ? 'Skrýt informace' : 'Zobrazit informace'
    }
  }
}
</script>

<style scoped>
.info-container {
  margin-bottom: .8em;
}

.info-section {
  max-width: 668px;
  margin: 0 auto;
  padding: 1em;
  color: #444;
  border: 1px dashed #ccc;
}

.btn-container {
  text-align: center;
  margin-top: 1em;
}
</style>