<template>
  <div class="input-group">
    <label for="cipherTextArea">Šifrový text</label>
    <textarea
      id="cipherTextArea"
      :value="value"
      :class="{ error: !isValid }"
      placeholder="zadej šifrový text"
      @input="valueChanged"
      rows="5"
    ></textarea>
    <p v-show="!isValid" class="error-msg error">{{ invalidFeedback }}</p>
  </div>
</template>

<script>
import { normalizeCipherText } from '@/ciphers/normalization'

export default {
  props: {
    value: String,
    maxLength: Number,
    isValid: {
      type: Boolean,
      default: true
    },
    invalidFeedback: String
  },
  emits: ['valueChanged'],
  methods: {
    valueChanged(event) {
      const value = event.target.value
      const normalizedValue = normalizeCipherText(value).substring(0, this.maxLength)
      event.target.value = normalizedValue
      this.$emit('valueChanged', normalizedValue)
    }
  }
}
</script>