import { encrypt, decrypt } from '@/ciphers/route/common'

/**
  * Zašifrovat text počtem sloupců
  * Cesta: diagonálně z horního levého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptFromTopLeft(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderFromTopLeft, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: diagonálně z horního levého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptFromTopLeft(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderFromTopLeft, { normalize })
}

/**
  * Zašifrovat text počtem sloupců
  * Cesta: diagonálně z dolního pravého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptFromBottomRight(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderFromBottomRight, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: diagonálně z dolního pravého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptFromBottomRight(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderFromBottomRight, { normalize })
}

/**
  * Zašifrovat text počtem sloupců
  * Cesta: diagonálně z horního pravého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptFromTopRight(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderFromTopRight, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: diagonálně z horního pravého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptFromTopRight(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderFromTopRight, { normalize })
}

/**
  * Zašifrovat text počtem sloupců
  * Cesta: diagonálně z dolního levého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptFromBottomLeft(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderFromBottomLeft, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: diagonálně z dolního levého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptFromBottomLeft(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderFromBottomLeft, { normalize })
}

// vrací seznam indexů po diagonálách
// diagonály zprava doleva
// příklad:
// 0 1 2
// 3 4 5
// 6 7 8
// return [[0], [1, 3], [2, 4, 6], [5, 7], [8]]
function partsRightToLeft(cols, rows) {
  const parts = []

  // loop přes všechny části
  // začátek na 1. řádků a zbytku posledního sloupce
  for (let i = 0; i < cols + rows - 1; i++) {
    // určení počátečního sloupce a řádku
    let col = i < cols ? i : cols - 1
    let row = i < cols ? 0 : i - cols + 1

    // vytvoření části
    // průchod od horního diagonálně dolů a vlevo
    const part = []
    while (col >= 0 && row < rows) {
      part.push(cols * row + col)
      col -= 1
      row += 1
    }
    parts.push(part)
  }

  return parts
}

// vrací seznam indexů po diagonálách
// diagonály zleva doprava
// příklad:
// 0 1 2
// 3 4 5
// 6 7 8
// return [[6], [3, 7], [0, 4, 8], [1, 5], [2]]
function partsLeftToRight(cols, rows) {
  const parts = []

  for (let i = cols + rows - 2; i >= 0; i--) {
    // určení počátečního sloupce a řádku
    let col = i >= cols - 1 ? 0 : cols - i - 1
    let row = i >= cols - 1 ? i - cols + 1 : 0

    const part = []
    while (col < cols && row < rows) {
      part.push(cols * row + col)
      col += 1
      row += 1
    }

    parts.push(part)
  }

  return parts
}

// průchod po diagonálách
// každá s lichým indexem je obrácena
function orderFromTopLeft(cols, rows) {
  const parts = partsRightToLeft(cols, rows)
  const order = []

  for (let i = 0; i < parts.length; i++) {
    const part = i % 2 === 0 ? parts[i] : parts[i].reverse()
    for (const item of part) order.push(item)
  }

  return order
}

// průchod po diagonálách
// obrácené diagonály na základě počtu diagonál
function orderFromBottomRight(cols, rows) {
  const parts = partsRightToLeft(cols, rows)
  const order = []
  const remainder = parts.length % 2 === 0 ? 0 : 1

  for (let i = parts.length - 1; i >= 0; i--) {
    const part = i % 2 === remainder ? parts[i] : parts[i].reverse()
    for (const item of part) order.push(item)
  }

  return order
}

// průchod po diagonálách
// obrácené diagonály na základě počtu diagonál
function orderFromTopRight(cols, rows) {
  const parts = partsLeftToRight(cols, rows)
  const order = []
  const remainder = parts.length % 2 === 0 ? 1 : 0

  for (let i = parts.length - 1; i >= 0; i--) {
    const part = i % 2 === remainder ? parts[i] : parts[i].reverse()
    for (const item of part) order.push(item)
  }

  return order
}

// průchod po diagonálách
// každá se sudým indexem je obrácena
function orderFromBottomLeft(cols, rows) {
  const parts = partsLeftToRight(cols, rows)
  const order = []

  for (let i = 0; i < parts.length; i++) {
    const part = i % 2 === 1 ? parts[i] : parts[i].reverse()
    for (const item of part) order.push(item)
  }

  return order
}

// vrací objekt se všemi funkcemi
export default {
  encryptFromTopLeft,
  decryptFromTopLeft,
  encryptFromBottomRight,
  decryptFromBottomRight,
  encryptFromTopRight,
  decryptFromTopRight,
  encryptFromBottomLeft,
  decryptFromBottomLeft
}