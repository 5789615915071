<template>
  <div class="input-group">
    <label for="keyInput">Klíč</label>
    <input
      type="text"
      id="keyInput"
      :value="value"
      @input="valueChanged"
      :class="{ error: !isValid }"
      autocomplete="off"
    />
    <p v-show="!isValid" class="error-msg error">Klíč musí mít alespoň 2 písmena</p>
  </div>
</template>

<script>
import { normalizePlainText } from '@/ciphers/normalization'

export default {
  props: {
    value: String,
    isValid: Boolean
  },
  emits: ['valueChanged'],
  methods: {
    valueChanged(event) {
      const value = normalizePlainText(event.target.value)
      event.target.value = value
      this.$emit('valueChanged', value)
    }
  }
}
</script>