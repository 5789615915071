import { encrypt, decrypt } from '@/ciphers/route/common'

/**
  * Zašifrovat text počtem sloupců
  * Cesta: horizontálně z horního levého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptFromTopLeft(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderFromTopLeft, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: horizontálně z horního levého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptFromTopLeft(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderFromTopLeft, { normalize })
}

/**
  * Zašifrovat text počtem sloupců
  * Cesta: horizontálně z horního pravého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptFromTopRight(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderFromTopRight, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: horizontálně z horního pravého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptFromTopRight(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderFromTopRight, { normalize })
}

/**
  * Zašifrovat text počtem sloupců
  * Cesta: horizontálně z dolního levého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptFromBottomLeft(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderFromBottomLeft, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: horizontálně z dolního levého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptFromBottomLeft(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderFromBottomLeft, { normalize })
}

/**
  * Zašifrovat text počtem sloupců
  * Cesta: horizontálně z dolního pravého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptFromBottomRight(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderFromBottomRight, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: horizontálně z dolního pravého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptFromBottomRight(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderFromBottomRight, { normalize })
}

function orderFromTop(cols, rows, left) {
  const order = []
  let index = left ? 0 : cols - 1
  let offset = left ? 1 : -1

  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++) {
      order.push(index)
      index += offset
    }
    index += cols + (offset > 0 ? -1 : 1)
    offset = -offset
  }

  return order
}

function orderFromTopLeft(cols, rows) {
  return orderFromTop(cols, rows, true)
}

function orderFromTopRight(cols, rows) {
  return orderFromTop(cols, rows, false)
}

function orderFromBottom(cols, rows, left) {
  const order = []
  let index = left ? cols * (rows - 1) : cols * rows - 1
  let offset = left ? 1 : -1

  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++) {
      order.push(index)
      index += offset
    }
    index -= cols + (offset < 0 ? -1 : 1)
    offset = -offset
  }

  return order
}

function orderFromBottomLeft(cols, rows) {
  return orderFromBottom(cols, rows, true)
}

function orderFromBottomRight(cols, rows) {
  return orderFromBottom(cols, rows, false)
}

// vrací objekt se všemi funkcemi
export default {
  encryptFromTopLeft,
  decryptFromTopLeft,
  encryptFromTopRight,
  decryptFromTopRight,
  encryptFromBottomLeft,
  decryptFromBottomLeft,
  encryptFromBottomRight,
  decryptFromBottomRight
}