<template>
  <section-header headline="Šifra ÜBCHI" />

  <div class="container">
    <cipher-info>
      <h2>Šifra ÜBCHI</h2>

      <p>Jedná se o dvojitou sloupcovou transpozici. Během šifrování je <router-link to="/columnar">sloupcová transpozice</router-link> aplikována dvakrát po sobě. Pro obě transpozice se používá stejný klíč - fráze, která se skládá z určitého počtu slov. Před druhou transpozicí se podle tohoto počtu přidávají do textu klamače. Dvojitá sloupcová transpozice staví na skutečnosti, že transpozici je možné provést vícekrát po sobě, čímž se zvyšuje bezpečnost šifry. Existují i další varianty dvojité sloupcové transpozice, které pro každou transpozici vyžadují vlastní klíč.</p>
      <p>Šifru ÜBCHI používali Němci za 1. světové války. Nebyli však příliš důslední ve výměně klíčů a posílali opakující se zprávy (např. "všude je klid"), což Francouzům usnadnilo luštění.</p>
      <p><strong>Otevřený text se zašifruje pomocí sloupcové transpozice, čímž vznikne "částečný" šifrový text. Poté se na konec tohoto textu přidají klamače (např. písmeno "w"), jejichž počet odpovídá počtu slov v klíči. Nakonec se provede druhá sloupcová transpozice se stejným klíčem a výsledkem je šifrový text.</strong></p>
      <p>Šifru ÜBCHI si můžete vyzkoušet pomocí formuláře níže</p>
      <p>Po zadání klíče a otevřeného (šifrového) textu se pod formulářem zobrazí šifrovací tabulky pro obě transpozice.</p>
    </cipher-info>

    <multiple-key-input
      :value="keyValue"
      :isValid="isKeyValid"
      @valueChanged="keyChanged"
    />

    <div v-show="isKeyValid">
      <div class="text-group">
        <plain-text-area
          :value="plainText"
          @valueChanged="plainTextChanged"
        />
        <cipher-text-area
          :value="cipherText"
          @valueChanged="cipherTextChanged"
        />
      </div>

      <columnar-table
        label="1. šifrovací tabulka"
        :keyValue="keyWithoutSpaces"
        :permutation="permutation"
        :isValid="isKeyValid"
        :text="plainText"
      />
      
      <columnar-table
        label="2. šifrovací tabulka"
        :keyValue="keyWithoutSpaces"
        :permutation="permutation"
        :isValid="isKeyValid"
        :text="partCipherText"
      />
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/base/SectionHeader'
import InfoSection from '@/components/base/InfoSection'
import MultipleKeyInput from '@/components/input/MultipleKeyInput'
import PlainTextArea from '@/components/input/PlainTextArea'
import CipherTextArea from '@/components/input/CipherTextArea'
import ColumnarTable from '@/components/ColumnarTable'

import ubchi from '@/ciphers/ubchi'
import { keyPermutation } from '@/ciphers/permutation'

export default {
  components: {
    'section-header': SectionHeader,
    'cipher-info': InfoSection,
    'multiple-key-input': MultipleKeyInput,
    'plain-text-area': PlainTextArea,
    'cipher-text-area': CipherTextArea,
    'columnar-table': ColumnarTable
  },
  data() {
    return {
      isEncrypting: true,
      keyValue: '',
      plainText: '',
      cipherText: '',
      partCipherText: ''
    }
  },
  methods: {
    keyChanged(value) {
      this.keyValue = value
      if (this.isKeyValid) {
        if (this.isEncrypting) this.encrypt()
        else this.decrypt()
      }
    },
    plainTextChanged(value) {
      this.plainText = value
      this.isEncrypting = true
      this.encrypt()
    },
    cipherTextChanged(value) {
      this.cipherText = value
      this.isEncrypting = false
      this.decrypt()
    },
    encrypt() {
       const result = ubchi.encryptByPermutation(
        this.permutation,
        this.plainText,
        { numOfWords: this.numOfKeyWords, normalize: false }
      )
      this.partCipherText = result.partCipher
      this.cipherText = result.cipher
    },
    decrypt() {
      const result = ubchi.decryptByPermutation(
        this.permutation,
        this.cipherText,
        { numOfWords: this.numOfKeyWords, normalize: false }
      )
      this.partCipherText = result.partCipher
      this.plainText = result.text
    }
  },
  computed: {
    // pole s jednotlivými slovy klíče
    keyArray() {
      return this.keyValue.trim().split(' ')
    },
    keyWithoutSpaces() {
      return this.keyArray.join('')
    },
    numOfKeyWords() {
      return this.keyArray.length
    },
    permutation() {
      return keyPermutation(this.keyWithoutSpaces, { normalize: false })
    },
    isKeyValid() {
      return this.keyWithoutSpaces.length > 1
    }
  }
}
</script>