import { keyPermutationDuplicate as keyPermutation } from '@/ciphers/permutation'
import { normalizeCipher, normalizeText } from '@/ciphers/normalization'
import { createTwoDimArray } from '@/ciphers/utils'

/**
  * Zašifrovat text klíčem
  * Proběhne validace a normalizace
  * @param {string} key - Šifrový klíč
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encrypt(key, text) {
  const permutation = keyPermutation(key)
  return encryptByPermutation(permutation, text)
}

/**
  * Zašifrovat text abecedním vyčíslením
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} permutation - Abecední vyčíslení
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptByPermutation(permutation, text, { normalize = true } = {}) {
  if (normalize) text = normalizeText(text)

  const keyLength = permutation.length
  const cipherCols = createTwoDimArray(keyLength)

  // sloupce se stejným vyčíslením jsou v jednom poli
  for (let col = 0; col < keyLength; col++) {
    let cipherCol = ''
    for (let i = col; i < text.length; i += keyLength)
      cipherCol += text.charAt(i)

    const colPosition = permutation[col]
    cipherCols[colPosition].push(cipherCol)
  }

  // nahrazení vnořených polí řetězci
  // více sloupců se stejným vyčíslením je "sléváno" do jednoho
  for (let i = 0; i < keyLength; i++) {
    const cipherCol = cipherCols[i]
    const size = cipherCol.length

    if (size == 0) cipherCols[i] = ''
    else if (size == 1) cipherCols[i] = cipherCol[0]
    else cipherCols[i] = mergeStringsInArray(cipherCol)
  }

  return cipherCols.join('').toUpperCase()
}

/**
  * Dešifrovat text klíčem
  * Proběhne validace a normalizace
  * @param {string} key - Šifrový klíč
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decrypt(key, cipher) {
  const permutation = keyPermutation(key)
  return decryptByPermutation(permutation, cipher)
}

/**
  * Dešifrovat text abecedním vyčíslením
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} permutation - Abecední vyčíslení
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptByPermutation(permutation, cipher, { normalize = true } = {}) {
  if (normalize) cipher = normalizeCipher(cipher)

  const keyLength = permutation.length
  const numOfRows = Math.ceil(cipher.length / keyLength)
  const numOfComplete = cipher.length - keyLength * (numOfRows - 1)
  const cipherCols = Array(keyLength).fill('')

  // colGroup je pole obsahující indexy sloupců,
  // které byly "sléty" dohromady
  const colGroups = colIndexGroups(permutation)
  let i = 0
  for (const colGroup of colGroups) {
    // výpočet indexu, kde aktuální sloupcová skupina končí
    let endIndex = i
    for (const colIndex of colGroup) {
      endIndex += numOfRows

      // je-li sloupec neúplný, snížit počet písmen
      const isIncomplete = colIndex >= numOfComplete
      if (isIncomplete) endIndex -= 1
    }

    // inverze "slévání" sloupců
    // sloupcová skupina se rozděluje do daného počtu sloupců
    // vzniklé sloupce jsou umístěny na příslušné pozice
    let j = 0
    while (i < endIndex) {
      const colIndex = colGroup[j]
      cipherCols[colIndex] += cipher.charAt(i++)
      j = (j + 1) % colGroup.length
    }
  }

  // vytvoření otevřeného textu ze sloupců
  let plainText = ''
  for (let row = 0; row < numOfRows; row++) {
    for (let col = 0; col < keyLength; col++) {
      plainText += cipherCols[col].charAt(row)
    }
  }

  return plainText.toLowerCase()
}

// př.: strings = ['aaaa', 'bbb']
// result = 'abababa'
function mergeStringsInArray(strings) {
  const length = strings[0].length
  let result = ''

  for (let i = 0; i < length; i++) {
    for (const str of strings) {
      result += str.charAt(i)
    }
  }
  
  return result
}

// vrací pole skupin sloupců
// obsahuje pole s indexy sloupců, které tvoří jednu skupinu
// v jedné skupině jsou indexy sloupců, které byly sléty dohromady
function colIndexGroups(permutation) {
  let order = getPermutationOrder(permutation)
  let groups = []

  groups.push([order[0]])
  for (let i = 1; i < permutation.length; i++) {
    const colIndex = order[i]
    const currentNumber = permutation[colIndex]
    const previousNumber = permutation[order[i - 1]]

    if (currentNumber === previousNumber) {
      // patří do aktuálně poslední skupiny
      groups[groups.length - 1].push(colIndex)
    } else {
      // vytvoří se nová skupina
      groups.push([colIndex])
    }
  }

  return groups
}

// vrací pole indexů
// seřazení podle abecedního pořadí
function getPermutationOrder(permutation) {
  let order = [...Array(permutation.length).keys()]

  order.sort((x, y) => permutation[x] - permutation[y])
  
  return order
}

// vrací objekt se všemi funkcemi
export default {
  encrypt,
  encryptByPermutation,
  decrypt,
  decryptByPermutation
}