<template>
  <grille-size-input
    :value="size"
    :isValid="isSizeValid"
    label="Velikost mřížky"
    invalidFeedback="Velikost mřížky musí být alespoň 4"
    @valueChanged="sizeChanged"
  />

  <section v-show="isSizeValid" class="grille-section">
    <h2>Mřížka</h2>

    <div class="grille-container">
      <table>
        <tbody>
          <tr v-for="(row, rowIndex) in grille" :key="rowIndex">
            <grille-cell
              v-for="(cell, cellIndex) in row"
              :key="cellIndex"
              :grille="grille"
              :row="rowIndex"
              :col="cellIndex"
              @select="selectCell"
            ></grille-cell>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="grille-actions">
      <button
        v-show="!isComplete"
        @click="randomizeGrille"
        class="special-btn"
      >Náhodně doplnit mřížku</button>

      <p v-show="!isComplete" class="error-msg error">Mřížka není úplná</p>

      <button
        v-show="isComplete"
        @click="resetGrille"
        class="special-btn"
      >Resetovat mřížku</button>
    </div>
  </section>
</template>

<script>
import KeyNumberInput from '@/components/input/KeyNumberInput'
import FleissnerGrilleCell from '@/components/FleissnerGrilleCell'

import { randomlyFillIn } from '@/composables/random-grille'

export default {
  components: {
    'grille-size-input': KeyNumberInput,
    'grille-cell': FleissnerGrilleCell
  },
  emits: ['valueChanged'],
  data() {
    return {
      size: 5,
      grille: null,
      numOfSelected: 0
    }
  },
  created() {
    this.resetGrille()
  },
  methods: {
    resetGrille() {
      const grille = []
      for (let i = 0; i < this.size; i++)
        grille.push(new Array(this.size).fill(0))
      // pokud je size liché, prostřední cell nelze vybrat
      if (this.size % 2 === 1) {
        const middle = Math.floor(this.size / 2)
        grille[middle][middle] = 2
      }
      this.grille = grille
      this.numOfSelected = 0
      // parent dostává buď kompletní mřížku nebo null
      this.$emit('valueChanged', null)
    },
    sizeChanged(value) {
      this.size = value
      this.resetGrille()
    },
    selectCell(row, col) {
      const value = this.grille[row][col]
      if (value === 0) this.cutCell(row, col)
      else if (value === 1) this.fillCell(row, col)
      // parent dostává buď kompletní mřížku nebo null
      this.$emit('valueChanged', this.isComplete ? this.grille : null)
    },
    cutCell(row, col) {
      this.grille[row][col] = 1
      this.grille[col][this.size - 1 - row] = 2
      this.grille[this.size - 1 - row][this.size - 1 - col] = 2
      this.grille[this.size - 1 - col][row] = 2
      // aktualizuje počtu vybraných políček
      this.numOfSelected += 1
    },
    fillCell(row, col) {
      this.grille[row][col] = 0
      this.grille[col][this.size - 1 - row] = 0
      this.grille[this.size - 1 - row][this.size - 1 - col] = 0
      this.grille[this.size - 1 - col][row] = 0
      // aktualizuje počtu vybraných políček
      this.numOfSelected -= 1
    },
    randomizeGrille() {
      randomlyFillIn(this.grille)
      this.numOfSelected = Math.floor((this.size * this.size) / 4)
      this.$emit('valueChanged', this.grille)
    }
  },
  computed: {
    isSizeValid() {
      return this.size >= 4
    },
    isComplete() {
      return Math.floor((this.size * this.size) / 4) === this.numOfSelected
    }
  }
}
</script>

<style scoped>
.grille-section {
  text-align: center;
}

.grille-container {
  overflow: auto;
}

table {
  margin: 0 auto;
  border-collapse: collapse;
}

.grille-actions {
  margin-top: 1em;
}
</style>