import { normalizeCipher, normalizeText } from "@/ciphers/normalization"

/**
  * Zašifrovat text mřížkou
  * Parametr normalize nastavuje validaci a normalizaci
  * U mřížky se validují pouze rozměry
  * @param {string} grille - Šifrová mřížka, vytvoří se kopie,
  *                          2-rozměrné boolean pole, true vybrané políčko
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encrypt(grille, text, { normalize = true } = {}) {
  if (normalize) {
    check(grille)
    text = normalizeText(text)
  }

  // vytvoření kopie mřížky, aby vstup nebyl upravován
  grille = copy(grille)

  const size = grille.length
  const cipherArray = Array(size * size)

  // provádí se 4 průchody a 3 rotace
  // písmena jsou postupně umisťována do cipherArray
  // v pořadí, v němž mají v šifrovaném textu být
  let rotations = 0
  let i = 0
  while (rotations < 4) {
    for (let row = 0; row < size; row++) {
      for (let col = 0; col < size; col++) {
        if (grille[row][col]) {
          const index = row * size + col
          cipherArray[index] = text.charAt(i++)
        }
      }
    }
    // poslední rotace je zbytečná
    if (rotations < 3) rotate(grille)
    rotations += 1
  }

  return cipherArray.join('').toUpperCase()
}

/**
  * Dešifrovat text mřížkou
  * Parametr normalize nastavuje validaci a normalizaci
  * U mřížky se validují pouze rozměry
  * @param {string} grille - Šifrová mřížka, vytvoří se kopie,
  *                          2-rozměrné boolean pole, true vybrané políčko
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decrypt(grille, cipher, { normalize = true } = {}) {
  if (normalize) {
    check(grille)
    cipher = normalizeCipher(cipher)
  }

  // vytvoření kopie mřížky, aby vstup nebyl upravován
  grille = copy(grille)

  const size = grille.length
  const middle = (size * size) / 2
  let plainText = ''

  // provádí se 4 průchody a 3 rotace
  let rotations = 0
  while (rotations < 4) {
    for (let row = 0; row < size; row++) {
      for (let col = 0; col < size; col++) {
        if (grille[row][col]) {
          let index = row * size + col
          // pokud je tabulka o liché délce, prostřední pole se ignoruje
          // pro další index je třeba odečíst 1
          if (size % 2 === 1 && index > middle) index -= 1
          plainText += cipher.charAt(index)
        }
      }
    }
    // poslední rotace je zbytečná
    if (rotations < 3) rotate(grille)
    rotations += 1
  }

  return plainText.toLowerCase()
}

// kontrola, zda je grille 2-dim pole se spravnými rozměry
function check(grille) {
  if (!Array.isArray(grille))
    throw new Error(`${grille} is not an array`)

  const size = grille.length
  for (const row of grille) {
    if (!Array.isArray(row) || row.length !== size)
      throw new Error(`${row} is not a valid row in ${grille}`)
  }
}

// vytvoří kopii mřížky
function copy(grille) {
  const copy = []
  for (const row of grille) {
    copy.push(row.slice(0))
  }
  return copy
}

// pootočí mřížku o 90% ve směru hodinových ručiček
// operace se provádí na vstupní mřížce
function rotate(grille) {
  const size = grille.length
  const x = Math.floor(size / 2)
  const y = size - 1

  for (let i = 0; i < x; i++) {
    for (let j = i; j < y - i; j++) {
      const temp = grille[i][j]
      grille[i][j] = grille[y - j][i]
      grille[y - j][i] = grille[y - i][y - j]
      grille[y - i][y - j] = grille[j][y - i]
      grille[j][y - i] = temp
    }
  }

  return grille
}

// vrací objekt se všemi funkcemi
export default { encrypt, decrypt }