import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home'
import Columnar from '@/views/Columnar'
import RailFence from '@/views/RailFence'
import Myszkowski from '@/views/Myszkowski'
import Ubchi from '@/views/Ubchi'
import Fleissner from '@/views/Fleissner'
import Route from '@/views/Route'
import Solve from '@/views/Solve'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sifra-railfence',
    name: 'RailFence',
    meta: {
      title: 'Šifra Rail Fence'
    },
    component: RailFence
  },
  {
    path: '/sloupcova-transpozice',
    name: 'Columnar',
    meta: {
      title: 'Sloupcová transpozice'
    },
    component: Columnar
  },
  {
    path: '/myszkowskiho-transpozice',
    name: 'Myszkowski',
    meta: {
      title: 'Myszkowskiho transpozice'
    },
    component: Myszkowski
  },
  {
    path: '/sifra-ubchi',
    name: 'Ubchi',
    meta: {
      title: 'Šifra ÜBCHI'
    },
    component: Ubchi
  },
  {
    path: '/fleissnerova-mrizka',
    name: 'Fleissner',
    meta: {
      title: 'Fleissnerova mřížka'
    },
    component: Fleissner
  },
  {
    path: '/sifra-route',
    name: 'Route',
    meta: {
      title: 'Šifra Route'
    },
    component: Route
  },
  {
    path: '/prolomit',
    name: 'Solve',
    meta: {
      title: 'Prolomení'
    },
    component: Solve
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = 'Transpoziční šifry'
  if (to.meta.title) {
    document.title = to.meta.title + ' | Transpoziční šifry'
  }
  next()
})

export default router
