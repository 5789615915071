import { keyPermutation } from '@/ciphers/permutation'
import { normalizeText, normalizeCipher } from '@/ciphers/normalization'
import { createTwoDimArray } from '@/ciphers/utils'

/**
  * Zašifrovat text klíčem
  * Proběhne validace a normalizace
  * @param {string} key - Šifrový klíč
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encrypt(key, text) {
  const permutation = keyPermutation(key)
  return encryptByPermutation(permutation, text)
}

/**
  * Zašifrovat text abecedním vyčíslením
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} permutation - Abecední vyčíslení
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptByPermutation(permutation, text, { normalize = true } = {}) {
  if (normalize) text = normalizeText(text)

  const keyLength = permutation.length
  const cipherCols = Array(keyLength).fill('')

  // vytváření šifrových sloupců v permutovaném pořadí
  for (let col = 0; col < keyLength; col++) {
    const colPosition = permutation[col]
    for (let i = col; i < text.length; i += keyLength)
      cipherCols[colPosition] += text.charAt(i)
  }

  return cipherCols.join('').toUpperCase()
}

/**
  * Dešifrovat text klíčem
  * Proběhne validace a normalizace
  * @param {string} key - Šifrový klíč
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
function decrypt(key, cipher) {
  const permutation = keyPermutation(key)
  return decryptByPermutation(permutation, cipher)
}

/**
  * Dešifrovat text abecedním vyčíslením
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} permutation - Abecední vyčíslení
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
function decryptByPermutation(permutation, cipher, { normalize = true } = {}) {
  if (normalize) cipher = normalizeCipher(cipher)

  const keyLength = permutation.length
  const numOfRows = Math.ceil(cipher.length / keyLength)
  const numOfComplete = cipher.length - keyLength * (numOfRows - 1)
  const colPositions = columnPositions(permutation)

  // získání sloupců
  const cipherCols = createTwoDimArray(keyLength)
  let i = 0
  for (let colPosition of colPositions) {
    let endIndex = i + numOfRows

    // je-li sloupec neúplný, snížit počet písmen
    const isIncomplete = colPosition >= numOfComplete
    if (isIncomplete) endIndex -= 1

    // zaplnění sloupce
    while (i < endIndex) {
      cipherCols[colPosition].push(cipher[i++])
    }

    // přidání 'prázdného znaku' kvůli neúplným sloupcům
    // aby se neobjevovalo undefined
    if (isIncomplete) cipherCols[colPosition].push('')
  }

  //zpracování sloupců šifrovaného textu do otevřeného
  let plainText = ''
  for (let row = 0; row < numOfRows; row++) {
    for (let col = 0; col < keyLength; col++) {
      plainText += cipherCols[col][row]
    }
  }

  return plainText.toLowerCase()
}

// získání původních pozic sloupců
// aby mohly být při dešifrování správně umístěny
function columnPositions(permutation) {
  const keyLength = permutation.length
  let positions = [...Array(keyLength).keys()]

  positions.sort((x, y) => permutation[x] - permutation[y])

  return positions
}

// vrací objekt se všemi funkcemi
export default {
  encrypt,
  encryptByPermutation,
  decrypt,
  decryptByPermutation
}