<template>
  <td :class="activeClass" @click="select"></td>
</template>

<script>
export default {
  props: {
    grille: Array,
    row: Number,
    col: Number
  },
  emits: ['select'],
  methods: {
    select() {
      this.$emit('select', this.row, this.col)
    }
  },
  computed: {
    activeClass() {
      const value = this.grille[this.row][this.col]
      if (value === 0) return ''
      return value === 1 ? 'active' : 'inactive'
    }
  }
}
</script>

<style scoped>
td {
  min-width: 46px;
  height: 46px;
  background: #eee;
  border: 1px solid #999;
  cursor: pointer;
}

td:hover {
  opacity: 0.8;
}

.active {
  background-color: white;
}

.inactive {
  background-color: var(--error-color);
}
</style>