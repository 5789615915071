<template>
  <section-header headline="Šifra Route" />

  <div class="container">
    <cipher-info>
      <h2>Šifra Route</h2>

      <p>Šifra Route je jednoduchá geometrická transpozice, při níž se používá tabulka čtvercového nebo obdélníkového tvaru. <strong>Otevřený text se do této tabulky vepíše a šifrový text se vygeneruje průchodem tabulkou po zvolené cestě (odtud název, route = cesta). Příkladem cesty může být "spirála ve směru hodinových ručiček začínající v levém horním rohu". Zvolená cesta spolu s rozměry tabulky představují klíč šifry.</strong></p>

      <p>Mezi základní typy cest patří spirální, horizontální, vertikální a diagonální cesty. Kromě typu je nutné specifikovat počáteční bod a v některých případech směr. Například u spirální se může začínat v libovolném rohovém políčku nebo uprostřed a pokračovat ve směru nebo proti směru hodinových ručiček.</p>

      <p>Šifru Route si můžete vyzkoušet pomocí formuláře níže. Můžete si z nabídky zvolit cestu a poté psát otevřený (šifrový) text.</p>
    </cipher-info>

    <key-number-input
      :value="numOfCols"
      :isValid="isNumOfColsValid"
      label="Počet sloupců"
      invalidFeedback="Počet sloupců musí být alespoň 2"
      @valueChanged="numOfColsChanged"
    />

    <div v-show="isNumOfColsValid">
      <route-picker
        :selectedRoute="selectedRoute"
        :routes="routes"
        @valueChanged="routeChanged"
      />

      <div class="text-group">
        <plain-text-area
          :value="plainText"
          :isValid="isPlainTextValid"
          :invalidFeedback="invalidPlainTextFeedback"
          @valueChanged="plainTextChanged"
        />
        <cipher-text-area
          :value="cipherText"
          :isValid="isCipherTextValid"
          :invalidFeedback="invalidCipherTextFeedback"
          @valueChanged="cipherTextChanged"
        />
      </div>

      <button 
        v-show="!isPlainTextValid"
        @click="completePlainText"
        class="special-btn"
      >Doplnit otevřený text</button>
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/base/SectionHeader'
import InfoSection from '@/components/base/InfoSection'
import KeyNumberInput from '@/components/input/KeyNumberInput'
import RoutePicker from '@/components/RoutePicker'
import PlainTextArea from '@/components/input/PlainTextArea'
import CipherTextArea from '@/components/input/CipherTextArea'

import { routes } from '@/composables/route-ciphers'

export default {
  components: {
    'section-header': SectionHeader,
    'cipher-info': InfoSection,
    'key-number-input': KeyNumberInput,
    'route-picker': RoutePicker,
    'plain-text-area': PlainTextArea,
    'cipher-text-area': CipherTextArea,
  },
  data() {
    return {
      isEncrypting: true,
      numOfCols: 4,
      routes: routes,
      selectedRoute: routes[0],
      plainText: '',
      cipherText: ''
    }
  },
  methods: {
    numOfColsChanged(value) {
      this.numOfCols = value
      if (this.isNumOfColsValid) {
        if (this.isEncrypting) this.encrypt()
        else this.decrypt()
      }
    },
    routeChanged(value) {
      this.selectedRoute = value
      if (this.isEncrypting) this.encrypt()
      else this.decrypt()
    },
    plainTextChanged(value) {
      this.plainText = value
      this.isEncrypting = true
      this.encrypt()
    },
    cipherTextChanged(value) {
      this.cipherText = value
      this.isEncrypting = false
      this.decrypt()
    },
    encrypt() {
      this.cipherText = ''
      if (!this.isPlainTextValid) return

      const encrypt = this.selectedRoute.encrypt
      this.cipherText = encrypt(
        this.numOfCols,
        this.plainText,
        { normalize: false }
      )
    },
    decrypt() {
      this.plainText = ''
      if (!this.isCipherTextValid) return

      const decrypt = this.selectedRoute.decrypt
      this.plainText = decrypt(
        this.numOfCols,
        this.cipherText,
        { normalize: false }
      )
    },
    completePlainText() {
      const length = this.plainText.length
      const remainder = this.numOfCols - (length % this.numOfCols)
      this.plainText += 'x'.repeat(remainder)
      this.encrypt()
    },
    invalidTextFeedback(length) {
      const remainder = this.numOfCols - (length % this.numOfCols)
      return `Neúplná tabulka - ${length}/${length + remainder} písmen`
    }
  },
  computed: {
    isNumOfColsValid() {
      return this.numOfCols > 1
    },
    isPlainTextValid() {
      return !this.isEncrypting || this.plainText.length % this.numOfCols == 0
    },
    isCipherTextValid() {
      return this.isEncrypting || this.cipherText.length % this.numOfCols == 0
    },
    invalidPlainTextFeedback() {
      return this.invalidTextFeedback(this.plainText.length)
    },
    invalidCipherTextFeedback() {
      return this.invalidTextFeedback(this.cipherText.length)
    }
  }
}
</script>