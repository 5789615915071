import horizontal from '@/ciphers/route/horizontal'
import vertical from '@/ciphers/route/vertical'
import diagonal from '@/ciphers/route/diagonal'
import spiral from '@/ciphers/route/spiral'

// vrací objekt se všemi objekty reprezentující typ cest
export default {
  horizontal,
  vertical,
  diagonal,
  spiral
}