import { encrypt, decrypt } from '@/ciphers/route/common'

/**
  * Zašifrovat text počtem sloupců
  * Cesta: vertikálně z horního levého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptFromTopLeft(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderFromLeftDown, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: vertikálně z horního levého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptFromTopLeft(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderFromLeftDown, { normalize })
}

/**
  * Zašifrovat text počtem sloupců
  * Cesta: vertikálně z horního pravého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptFromTopRight(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderFromRightDown, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: vertikálně z horního pravého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptFromTopRight(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderFromRightDown, { normalize })
}

/**
  * Zašifrovat text počtem sloupců
  * Cesta: vertikálně z dolního levého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptFromBottomLeft(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderFromLeftUp, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: vertikálně z dolního levého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptFromBottomLeft(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderFromLeftUp, { normalize })
}

/**
  * Zašifrovat text počtem sloupců
  * Cesta: vertikálně z dolního pravého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} text - Otevřený text
  * @returns {string} Šifrový text
*/
export function encryptFromBottomRight(numOfCols, text, { normalize = true } = {}) {
  return encrypt(numOfCols, text, orderFromRightUp, { normalize })
}

/**
  * Dešifrovat text počtem sloupců
  * Cesta: vertikálně z dolního pravého rohu
  * Parametr normalize nastavuje validaci a normalizaci
  * @param {string} numOfCols - Počet sloupců
  * @param {string} cipher - Šifrový text
  * @returns {string} Otevřený text
*/
export function decryptFromBottomRight(numOfCols, cipher, { normalize = true } = {}) {
  return decrypt(numOfCols, cipher, orderFromRightUp, { normalize })
}

function orderFromLeft(cols, rows, down) {
  const order = []
  let index = down ? 0 : cols * (rows - 1)
  let offset = down ? cols : -cols

  for (let col = 0; col < cols; col++) {
    for (let row = 0; row < rows; row++) {
      order.push(index)
      index += offset
    }
    index -= offset - 1
    offset = -offset
  }

  return order
}

function orderFromLeftDown(cols, rows) {
  return orderFromLeft(cols, rows, true)
}

function orderFromLeftUp(cols, rows) {
  return orderFromLeft(cols, rows, false)
}

function orderFromRight(cols, rows, down) {
  const order = []
  let index = down ? cols - 1 : cols * rows - 1
  let offset = down ? cols : -cols

  for (let col = 0; col < cols; col++) {
    for (let row = 0; row < rows; row++) {
      order.push(index)
      index += offset
    }
    index -= offset + 1
    offset = -offset
  }

  return order
}

function orderFromRightDown(cols, rows) {
  return orderFromRight(cols, rows, true)
}

function orderFromRightUp(cols, rows) {
  return orderFromRight(cols, rows, false)
}

// vrací objekt se všemi funkcemi
export default {
  encryptFromTopLeft,
  decryptFromTopLeft,
  encryptFromTopRight,
  decryptFromTopRight,
  encryptFromBottomLeft,
  decryptFromBottomLeft,
  encryptFromBottomRight,
  decryptFromBottomRight
}