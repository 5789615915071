<template>
  <div class="input-group">
    <label for="keyNumberInput">{{ label }}</label>
    <input
      type="number"
      id="keyNumberInput"
      :value="value"
      @input="valueChanged"
      :class="{ error: !isValid }"
    />
    <p v-show="!isValid" class="error-msg error">{{ invalidFeedback }}</p>
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    isValid: Boolean,
    label: String,
    invalidFeedback: String
  },
  emits: ['valueChanged'],
  methods: {
    valueChanged(event) {
      let value = event.target.value
      value = value == '' ? '0' : value
      event.target.value = value
      this.$emit('valueChanged', parseInt(value))
    }
  }
}
</script>