<template>
  <section class="columnar-table">
    <h2>{{ label }}</h2>

    <table>
      <thead>
        <tr class="key-head">
          <th v-for="(letter, index) in keyValue" :key="index">
            {{ letter }}
          </th>
        </tr>
        <tr class="permutation-head">
          <th v-for="(position, index) in permutation" :key="index">
            {{ position + 1 }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in rows" :key="index">
          <td v-for="(letter, index) in row" :key="index">
            {{ letter }}
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Šifrovací tabulka'
    },
    keyValue: String,
    permutation: Array,
    isValid: Boolean,
    text: String
  },
  computed: {
    rows() {
      if (!this.isValid || this.text.length === 0) return []
      
      const rowSize = this.keyValue.length
      // rozděluje text na řetězce o délce rowSize, zdroj: https://stackoverflow.com/a/7033662
      const rows = this.text.match(new RegExp('.{1,' + rowSize + '}', 'g'))
      // doplňuje mezery, aby řádek byl úplný
      const numOfSpaces = rowSize - rows[rows.length - 1].length
      rows[rows.length - 1] += ' '.repeat(numOfSpaces)
      return rows
    }
  }
}
</script>

<style scoped>
.columnar-table {
  overflow-x: auto;
  text-align: center;
  margin-top: 1.6em;
}

.columnar-table table {
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  margin: 0 auto;
}

.key-head th {
  background-color: #d6d8db;
}

.permutation-head th {
  color: #fff;
  background-color: #343a40;
}

th, td {
  padding: .3em;
  min-width: 40px;
}

td {
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
}
</style>